module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://maison-graciet-martyr.gracietco-vt-prod-lamp01.dcsrv.eu/wp/graphql","production":{"allow401Images":true,"allow404Images":true,"hardCacheMediaFiles":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"30","matomoUrl":"https://stats-agence.graciet-co.fr/","siteUrl":"https://maison-graciet-martyr.gracietco-vt-prod-web02.dcsrv.eu/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-martyr","short_name":"gatsby-martyr","start_url":"/","background_color":"#ece0cf","theme_color":"#5d421d","display":"standalone","icon":"static/icon-512x512.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dbc0995348876bce419857ccb86d11b8"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
